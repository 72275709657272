import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import Old2005BlogPage from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—November 2006" />
	<Old2005BlogPage>
		<h2>November 2006</h2>




		<h3 className="blogdate">
		Tuesday, November 28, 2006
		</h3>






		<h4 className="blogitemtitle">
		I drink from the bottle
		</h4>





			<div className="blogitembody">
			As read on the back label of a bottle of Mac's Gold All Malt Lager:<br /><br />"<span style={{fontSize: '85%'}}>Drink from a glass. Even if it has a smurf on the outside and used to contain chocolate spread.  How can you enjoy the depth of colouration our brewers strived to achieve if you can't see it? How can you delight in bubbles winking at the brim if you haven't furnished a brim for them to wink at? You don't need to drink from a bottle. You're not 6 months old anymore. Release the luminous fluid, watch the head form, raise the glass to your lips, inhale the aroma and then drink deeply.</span>"<br /><br />I like drinking from the bottle. I am a rebel.
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 2:19 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Thursday, November 23, 2006
		</h3>






		<h4 className="blogitemtitle">
		A glance at my surroundings
		</h4>





			<div className="blogitembody">
			"Wellington... you can't beat it on a fine day." That's what "they" say. If you stay here long enough and look at your surroundings, you will indeed find fine days; it happened to me and I took a few pictures to prove it!<br /><br />Here's the view from my flat.<br /><br />
			<StaticImage
			src="../../images/photos/old2005BlogPics/20061123/F1000017.jpg"
			alt="photos from Wellington, New Zealand"
			placeholder="blurred"
			layout="constrained"
			className=""
			imgClassName="img--bordered-small"
			width={400}
			/>
			<br /><br />This is Hataitai, sort-of. I live here. See that blue-grey house in the center? I'm in the house to the right of it (you can only see the roof).<br /><br />
			<StaticImage
			src="../../images/photos/old2005BlogPics/20061123/F1000015.jpg"
			alt="photos from Wellington, New Zealand"
			placeholder="blurred"
			layout="constrained"
			className=""
			imgClassName="img--bordered-small"
			width={400}
			/>
			<br /><br />I live a few doors down from the Embassy for the Islamic Republic of Iran. I wonder if they have connections for some very fresh dates? I love dates. I should start taking more pictures with my camera vertical - they fit bigger on my blog.<br /><br />
			<StaticImage
			src="../../images/photos/old2005BlogPics/20061123/F1000014.jpg"
			alt="photos from Wellington, New Zealand"
			placeholder="blurred"
			layout="constrained"
			className=""
			imgClassName="img--bordered-small"
			width={400}
			/>
			<br /><br />This is a home (a bit below mine) that has a trolley-tram for access to the bayside street below.<br /><br />
			<StaticImage
			src="../../images/photos/old2005BlogPics/20061123/F1000005.jpg"
			alt="photos from Wellington, New Zealand"
			placeholder="blurred"
			layout="constrained"
			className=""
			imgClassName="img--bordered-small"
			width={400}
			/>
			<br /><br />Here's another home that has a trolley-tram. I can see this home (a much different angle) when I look out my window. I wonder if the people in this home live in glorious splendor - I wonder if they have central heating? Notice how the water is flat in the bay... VERY RARE here in Windy Wellington.<br /><br />
			<StaticImage
			src="../../images/photos/old2005BlogPics/20061123/F1000013.jpg"
			alt="photos from Wellington, New Zealand"
			placeholder="blurred"
			layout="constrained"
			className=""
			imgClassName="img--bordered-small"
			width={400}
			/>
			<br /><br />Speaking of wind, I am amazed that all the wires lining the streets and properties of Wellington are still strung. Here is a wonderful display of "wired and wireless"... a lovely mess. That's a radio tower in the top right. The home on the right, behind a tree, also has a satellite dish. In the immediate foreground are cables for the electric buses. I've yet to see a home that does NOT have radio antennae on it's roof.<br /><br />
			<StaticImage
			src="../../images/photos/old2005BlogPics/20061123/F1000003.jpg"
			alt="photos from Wellington, New Zealand"
			placeholder="blurred"
			layout="constrained"
			className=""
			imgClassName="img--bordered-small"
			width={400}
			/>
			<br /><br />I cropped this next pic so you can see the details better. There is a myriad of plants here that are foreign to me. This is an example of such a plant.<br /><br />
			<StaticImage
			src="../../images/photos/old2005BlogPics/20061123/F1000009.jpg"
			alt="photos from Wellington, New Zealand"
			placeholder="blurred"
			layout="constrained"
			className=""
			imgClassName="img--bordered-small"
			width={400}
			/>
			<br /><br />Now THIS is how a van should be - 4x4, touring machine. Josh owns this... not Jesse, unfortunately. Josh lived in the bush, north of Auckland, for four years practicing yoga, and now abseils windows downtown Wellington. Him and his wife are saving to buy property in the bush.<br /><br />
			<StaticImage
			src="../../images/photos/old2005BlogPics/20061123/F1000016.jpg"
			alt="photos from Wellington, New Zealand"
			placeholder="blurred"
			layout="constrained"
			className=""
			imgClassName="img--bordered-small"
			width={400}
			/>
			<br /><br />Ciao for now!
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 5:07 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Wednesday, November 22, 2006
		</h3>






		<h4 className="blogitemtitle">
		Indie Movie Theatre
		</h4>





			<div className="blogitembody">
			No, I didn't write a song about an independent movie theatre... well, not yet.  Coming to you live from the Paramount Theatre in Wellington City - Jake, Liv, and I just poured over 500 glasses of bubbly to a bunch of people I've never met.  And a few for us.  Lately I don't know what to expect when I walk into this place for a shift!<br /><br />Last Friday I walked in and there were people naked everywhere.  Mostly girls, too, which is good.  They were all painted; apparently I was working a body-art convention/show, tres cool.  I poured some stiff drinks and made a few creamy lattes.  Met some cool people working at this theatre, today I met with a student movie director - he and his editor walked me through their short film and asked me to compose some soundtrack themes for certain scenes.  Crazy, I'll be working on that for the next few days!<br /><br />Last Sunday I watched a pre-screening of Borat... there is one very appropriate adjective for this new movie, and it is "outrageous".<br /><br />We're currently eating the paninis that didn't sell today.  And the ice cream that didn't get served.  And I just poured a Keineken down the sink because it sat and warmed for too long.  Yes, I still drink hot water every day, but not hot beer.  No it is not hot here.  Yes, today was the hottest day in Wellington in the 6 or 7 weeks that I've been here.  Yay!<br /><br />Stay tuned for a few pics of Wellington.  That's where I currently live.
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 7:37 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Monday, November 13, 2006
		</h3>






		<h4 className="blogitemtitle">
		I know this because Tyler knows this
		</h4>





			<div className="blogitembody">
			The difference between a latte and a flat white coffee is in the preparation of the milk. A flat white should have milk warmed to creaminess. A latte should have milk warmed further, thicker/frothier, to a slight sweetness. In either case, the milk should be poured into the coffee center such that the crema returns, undisturbed. Making frothy doodles in the crema is silly... crema is beautiful enough.
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 3:28 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Friday, November 10, 2006
		</h3>






		<h4 className="blogitemtitle">
		Rick Mercer distracts me once again
		</h4>





			<div className="blogitembody">
			Ah, I remember the days when I'd set aside anything to watch This Hour Has 22 Minutes. Now I am subscribed to Rick Mercer's blog @ <a href="http://rickmercer.blogspot.com/">http://rickmercer.blogspot.com/</a><br /><br />Sweeet.<br /><br />By the way, kumara is a sweet potato. But sweet potato is a yam (or is yam a sweet potato?). But yam is an oca. Nevermind. I wish the world would just agree. Wikipedia "oca" and you'll see what I mean.
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 11:00 PM &nbsp;
			</div>











		<h4 className="blogitemtitle">
		Tasty chocolate red bush
		</h4>





			<div className="blogitembody">
			Mr. Whittaker, meet Mr. Rooibos... oh yeah, that's good, mmmmmmm.<br /><br />Melt some dark, bittersweet chocolate (at least 70% cocoa mass) in your mouth, that's right, get it smudged everywhere inside your mouth - your tongue, gums, roof, etc. Then take a sip of some hot, freshly brewed rooibos tea. None of that flavored crazy stuff, just some plain, red rooibos tea. Green (unfermented) would likely be good, too.<br /><br />Yeah, it's good. Especially while listening to Melissa McLelland in the CD player.
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 10:17 PM &nbsp;
			</div>





		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
